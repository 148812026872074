<template>
  <div>
    <q-list separator>
      <q-item v-for="(item, key) in value" :key="key">
        <q-item-section>
          <q-item-label v-for="(citem, i) in item.value" :key="i">
            {{ getCode(item.code, i) }} - {{ citem }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
export default {
  name: "ProReportMultipleCommodityDropDownListTdField.vue",
  props: {
    value: null,
    filterOption: {},
  },
  methods: {
    getCode(item, key) {
      if (item.length === 0) return "";
      return item.slice(0, key + 1).join("-");
    },
  },
};
</script>
